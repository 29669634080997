<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('li_step.application_search') }}</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(searchData)" >
                    <b-row>
                        <b-col lg="6" sm="6" md="6" v-if="adminView">
                            <ValidationProvider name="Organization" vid="org_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro.organization') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.org_id"
                                :options="organizationList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" md="6">
                            <ValidationProvider name="Application Id" vid="application_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.application_id') }}
                                </template>
                                <b-form-input
                                plain
                                v-model="search.application_id"
                                id="application_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6" md="6">
                            <ValidationProvider name="Sample Number" vid="sample_number" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="sample_number"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.sample_number') }}
                                </template>
                                <b-form-input
                                plain
                                v-model="search.sample_number"
                                id="sample_number"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col class="col-sm-2">
                            <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
                </ValidationObserver>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.application_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col md="12" class="table-responsive">
                            <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(org_id)="data">
                                    {{ getMainStoreData('orgList', data.item.org_id) }}
                                </template>
                                <template v-slot:cell(service_id)="data">
                                    {{ getStoreData('licenseRegistration', 'serviceNamesList', data.item.service_id) }}
                                </template>
                                 <template v-slot:cell(company_name)="data">
                                    {{ ($i18n.locale==='bn') ? data.item.organizati_9606 : data.item.organizati_2112 }}
                                </template>
                                <template v-slot:cell(sample_number)="data">
                                    {{ $n(data.item.sample_number, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                    {{ getStepName(data.item.current_step) + ' ' + ServiceLastStepCheck(data.item.service_id, data.item.current_step, data.item.status) }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <span>
                                        <!-- Application View (Details) -->
                                        <router-link :to="{ name: 'app_detail', params: { stepChange }}" :title="$t('globalTrans.details')" :class="`btn btn-sm btn-info m-1`" size="sm"><i :class="`ri-eye-line`" @click="SetStep(data.item, 1)"></i></router-link>
                                        <!-- Application Forward -->
                                        <router-link :to="{ name: 'forward', params: { stepChange }}" :title="$t('globalTrans.forward')" :class="`btn btn-sm btn-primary m-1`" size="sm"><i :class="`fa fa-paper-plane`" @click="SetStep(data.item, 1)"></i></router-link>
                                        <!-- Application lab Assign Form -->
                                        <b-button class="mr-1" title="Lab Assign" v-b-modal.modal-1 variant=" iq-bg-success" size="sm" @click="setApplication(data.item)"><i class="fa fa-check m-0 "></i></b-button>
                                    </span>
                                </template>
                            </b-table>
                            <b-pagination
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                                />
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-1" size="xl" :title="$t('li_step.lab_assign')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <LabForm :stepId="stepId" :appDetails="appDetails"></LabForm>
        </b-modal>
    </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { stepDataList } from '../../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'
import common from '@/mixins/common'
import LabForm from './Form'
export default {
    mixins: [ModalBaseMasterList, common],
    components: {
        LabForm
    },
    data () {
        return {
        listLoading: false,
        stepChange: {
            application_id: [],
            step_id: 0,
            service_id: 0,
            org_id: 0,
            status: 1
        },
        search: {
            org_id: 0,
            application_id: '',
            sample_number: ''
        },
        menuId: '',
        adminView: false,
        columns: [],
        stepId: '',
        appDetails: ''
        }
    },
    created () {
      this.menuId = this.$route.params.id
      this.search.org_id = this.menuInfoList.org_id
      this.stepId = this.step.value
      if (this.auth.authUser.user_id === 1) {
          this.adminView = true
      } else {
          this.adminView = false
      }
      this.setCommonGrid()
      this.loadData()
    },
    computed: {
        organizationList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        auth () {
            return this.$store.state.Auth
        },
        menuInfoList () {
            const menuId = this.$route.params.id
            return this.$store.state.licenseRegistration.commonObj.menuInfoList.find(item => item.menu_id === parseInt(menuId))
        },
        step () {
            const stepId = this.menuInfoList.step_id
            return this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === parseInt(stepId))
        },
        laodList () {
            return this.$store.state.licenseRegistration.commonObj.laodList
        },
        local: function () {
            return this.$i18n.locale
        }
    },
    mounted () {
        core.index()
    },
    watch: {
        laodList: function (n, o) {
        if (n !== o) {
            this.loadData()
        }
        }
    },
    methods: {
        searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage,
                step_id: this.step.value,
                service_id: this.step.service_id,
                is_forward: this.menuInfoList.is_forward,
                user_type: this.$store.state.Auth.authUser.user_type,
                is_org_admin: this.$store.state.Auth.authUser.is_org_admin,
                office_id: this.$store.state.Auth.authUser.office_id,
                is_payment: 0
            })
            this.listLoading = true
            RestApi.getData(licenseRegistrationServiceBaseUrl, stepDataList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.listLoading = false
            })
        },
        setCommonGrid () {
            this.columns = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left', key: 'index' },
                { label: this.$t('globalTrans.application_id'), class: 'text-left', key: 'application_id' },
                { label: this.$t('globalTrans.company_name'), class: 'text-left', key: 'company_name' },
                { label: this.$t('li_step.sample_number'), class: 'text-left', key: 'sample_number' },
                { label: this.$t('globalTrans.status'), class: 'text-left', key: 'status' }
            ].concat(this.columns)
            this.columns.push({ label: this.$t('globalTrans.action'), class: 'text-left', key: 'action' })
        },
        getStepName (stId) {
            const step = this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === stId)
            return step !== undefined ? step.text : ''
        },
        SetStep (item, status) {
            this.stepChange.application_id[0] = item.application_id
            this.stepChange.step_id = item.current_step
            this.stepChange.service_id = item.service_id
            this.stepChange.org_id = item.org_id
            this.stepChange.status = status
        },
        setApplication (item) {
            this.appDetails = item
        },
        ServiceLastStepCheck (serviceId, stepId, status) {
            const tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
            const tmpIndVStepList = tmpStepList.find(item => item.step_id === parseInt(stepId))
            const order = tmpIndVStepList.order
            const orderCheck = tmpStepList.find(item => item.order === order + 1)
            if (status === 0) {
                return this.$t('globalTrans.processing')
            } else if (status === 2) {
                return this.$t('globalTrans.rejected')
            } else if (typeof orderCheck !== 'undefined') {
                return this.$t('globalTrans.pending')
            } else {
                return this.$t('globalTrans.completed')
            }
        }
    }
  }
</script>
